import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CookieService } from '@services/cookie.service';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/authentication.service';
import { Theme } from '@services/theme-selector.service';

import { environment } from '@environments/environment';

import { User } from '@core/models/user';

import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'novus-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent {
  password?: string;

  appearance = new FormGroup({
    homePage: new FormControl<string | null>(null),
    language: new FormControl<string>('en'),
    theme: new FormControl<Theme>(Theme.LIGHT)
  });
  form: UntypedFormGroup;
  user?: User;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private cookies: CookieService,
    private http: HttpClient
  ) {
    this.form = this.formBuilder.group({
      username: [''],
      email: [''],
      fullName: [''],
      phone: [''],
      address: ['']
    });

    this.userService.getCurrentUser()
    .subscribe(u => {
      if (!!u) {
        this.user = u;
        this.form.controls['username'].setValue(u.username || '');
        this.form.controls['email'].setValue(u.email || '');
        this.form.controls['fullName'].setValue(u.fullName || '');
        this.form.controls['phone'].setValue(u.phoneNumber || '');
        this.form.controls['address'].setValue(u.address || '');
        this.appearance.controls['homePage'].setValue(u.homePage || '/');
        this.appearance.controls['language'].setValue(u.language || 'en');
        this.appearance.controls['theme'].setValue(<Theme>u.theme || Theme.LIGHT);
      }
    });
  }

  saveAppearance() {
    if (this.user?.id == undefined) {
      return;
    }
    const data: { [key: string]: any } = {
      homePage: this.appearance.controls['homePage'].value || '/',
      language: this.appearance.controls['language'].value || 'en',
      theme: this.appearance.controls['theme'].value || Theme.LIGHT
    };
    this.userService.update(this.user.id, data)
    .subscribe(() => {
      this.cookies.setCookie('lang', data['language'], 365 * 100);
      window.location.reload();
    });
  }

  selfDelete() {
    if (this.user?.id == undefined) return;
    if (this.password == undefined) return;
    this.userService.delete(this.user.id, { password: this.password })
    .subscribe(() => {
      this.authService.logout();
    });
  }

  update() {
    if (this.user?.id == undefined) {
      return;
    }

    const data: { [key: string]: any } = {};
    if (this.form.controls['username'].value != this.user?.username) data['username'] = this.form.controls['username'].value;
    if (this.form.controls['email'].value != this.user?.email) data['email'] = this.form.controls['email'].value;
    if (this.form.controls['email'].value != this.user?.email) data['email'] = this.form.controls['email'].value;
    if (this.form.controls['fullName'].value != this.user?.fullName) data['fullName'] = this.form.controls['fullName'].value;
    if (this.form.controls['phone'].value != this.user?.phoneNumber) data['phoneNumber'] = this.form.controls['phone'].value;
    if (this.form.controls['address'].value != this.user?.address) data['address'] = this.form.controls['address'].value;

    this.userService
    .update(this.user.id, data)
    .pipe(
      //switchMap(() => this.userService.getCurrentUser())
    )
    .subscribe(u => {
      location.reload();
      // if (!!u) {
      //   this.form.controls['username'].setValue(u.username);
      //   this.form.controls['email'].setValue(u.email || '');
      // }
    });
  }

}
