import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { LayoutsModule } from '@layouts/layouts.module';

import { AppsRoutingModule } from './apps-routing.module';
import { AppsComponent } from './apps.component';


@NgModule({
  declarations: [
    AppsComponent
  ],
  imports: [
    SharedModule,
    LayoutsModule,
    AppsRoutingModule
  ]
})
export class AppsModule { }
