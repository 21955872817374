import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

import { environment } from '@environments/environment';
import { User } from '@core/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) { }

  createUser(newUser: { username: string, email: string, password: string, requirePasswordUpdate: boolean }) {
    return this.http.post(`${environment.apiUrl}/users`, newUser);
  }

  delete(id: number, body: { password?: string } = {}) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`, { body });
  }

  update(id: number, body: {}) {
    return this.http.put(`${environment.apiUrl}/users/${id}`, body);
  }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  getCurrentUser(): Observable<User | undefined> {
    if (!!this.authService.userValue?.userId) {
      return this.getById(this.authService.userValue.userId)
    }
    return of(undefined);
  }

  getApiKeys() {
    return this.http.get<any>(`${environment.apiUrl}/users/api`);
  }

  deleteApiKey(id: number) {
    return this.http.delete(`${environment.apiUrl}/users/api/${id}`);
  }

  addApiKey(body: { name: string, expires?: Date }) {
    return this.http.post(`${environment.apiUrl}/users/api`, body);
  }
}
