import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@services/authentication.service';
import { UserService } from '@services/user.service';
import { AboutComponent } from '@public/dialogs/about/about.component';

import { iif, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { User } from '@core/models/user';
import { Role } from '@core/models/role';

@Component({
  selector: 'novus-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent {

  user?: User;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) {
    this.authenticationService.user
    .pipe(
      switchMap(t => iif(() => !!t, this.userService.getById(t?.userId || -1), of(undefined)))
    )
    .subscribe(u => this.user = u);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  get isAdmin() {
    return this.user && this.user.role === Role.Admin;
  }

  logout() {
    this.authenticationService.logout();
  }

  openAbout() {
    this.dialog.open(AboutComponent);
  }

}
