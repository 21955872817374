<novus-input-card color="accent">
  <span title>Create new user</span>

  <form [formGroup]="form" (ngSubmit)="create()">
    <mat-form-field>
      <mat-label i18n="@@Username">Username</mat-label>
      <input matInput formControlName="username">
      <mat-error *ngIf="submitted && usernameError()">Username is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@Email">Email</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@Password">Password</mat-label>
      <input matInput type="password" formControlName="password">
      <mat-error *ngIf="submitted && passwordError()">Password is required</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="requireChange">Require user to change password (recommended)</mat-checkbox>
  </form>

  <button action color="success" mat-raised-button (click)="create()">Create new user</button>
</novus-input-card>
