import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

import { StatusService } from '@services/status.service';

@Component({
  selector: 'novus-system-logs',
  templateUrl: './system-logs.component.html',
  styleUrls: ['./system-logs.component.css']
})
export class SystemLogsComponent {
  displayedColumns = ['select', 'date', 'type', 'description', 'actions' ];
  logs = new MatTableDataSource<any>([
    //{ id: 12, type: 'user activity', description: 'Some event', date: new Date() }
  ]);
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private stats: StatusService
  ) {
    this.stats.getSystemLogs().subscribe(l => {
      console.log(l);
      this.logs.data = (<[]>l).sort((a: any, b: any) => new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1);
      if (this.sort) this.logs.sort = this.sort;
    });
  }

  masterToggle() {
  }

  isAllSelected() {
    return true;
  }


}
