import { AuthenticationService } from '@services/authentication.service';
import { ThemeSelectorService } from '@services/theme-selector.service';

export function appInitializer(authenticationService: AuthenticationService, theme: ThemeSelectorService) {
  return () => new Promise<void>((resolve, reject) => {
    authenticationService.refresh()
    .subscribe((token) => {
      if (token?.theme) {
        theme.setTheme(token.theme);
      }
      if (token && token['requirePasswordUpdate']) {
        authenticationService.requirePasswordChange();
      }
      resolve();
    }, err => {
      authenticationService.logout();
      resolve(err);
    });
  });
}
