import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {

  constructor() { }

}
