<form *ngIf="!submitted; else result" (ngSubmit)="addApiKey()">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [formControl]="name">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Expiration date</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="expiration">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <button mat-button (click)="addApiKey()">Save</button>
</form>
<ng-template #result>
  <h1 mat-dialog-title>Key created</h1>
  <div mat-dialog-content>
    API key successfully created. Please note it down since this will be the only time it is shown
    <p>
      {{key}}
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </div>

</ng-template>
