<mat-card>
  <mat-toolbar [color]="color">
    <ng-content select="[title]"></ng-content>
  </mat-toolbar>
  <mat-card-content>

    <ng-content></ng-content>

  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-actions align="end">
    <ng-content select="[action]"></ng-content>
  </mat-card-actions>
</mat-card>
