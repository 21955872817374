<main>
  <novus-sidebar-layout>
    <aside link>
      <button mat-menu-item routerLink="personal" routerLinkActive="active">Personal</button>
      <button mat-menu-item routerLink="security" routerLinkActive="active">Security</button>
    </aside>

    <section>
      <router-outlet></router-outlet>
    </section>
  </novus-sidebar-layout>
</main>
