<novus-input-card color="accent">
  <span title>Change password</span>
  <mat-form-field>
    <mat-label>Current password</mat-label>
    <input matInput type="password" required>
  </mat-form-field>

  <mat-form-field>
    <mat-label>New password</mat-label>
    <input matInput type="password" required>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Confirm password</mat-label>
    <input matInput type="password" required>
  </mat-form-field>

  <button action mat-raised-button color="success">Update password</button>
</novus-input-card>

<novus-input-card color="accent">
  <span title>API Keys</span>

  <mat-list role="list">
    <mat-list-item *ngFor="let key of keys">
      <mat-icon mat-list-icon>key</mat-icon>
      <div mat-line>{{ key.name }}</div>
      <div mat-line>Expires: {{ (key.expires | date) ?? 'Never' }}</div>
      <button mat-icon-button (click)="deleteApiKey(key.id)"><mat-icon>delete</mat-icon></button>
    </mat-list-item>
  </mat-list>

  <button action mat-raised-button color="success" (click)="newKey()">New key</button>
</novus-input-card>
