import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeSelectorService {
  private themeReplaySubject = new ReplaySubject<Theme>();
  themeSelected = this.themeReplaySubject.asObservable();

  constructor() {
    const theme = localStorage.getItem('theme') as Theme;
    if (theme == undefined) {
      this.setTheme(Theme.LIGHT);
    } else {
      this.setTheme(theme);
    }
  }

  toggleDarkTheme(): void {
    document.body.classList.toggle('dark');
    localStorage.setItem('theme', this.isDarkModeActive.toString());
  }

  private isDarkModeActive(): boolean {
    return document.body.classList.contains('dark');
  }

  public setTheme(theme: Theme) {
    Object.values(Theme).forEach(t => {
      document.body.classList.remove(t);
    });
    document.body.classList.add(theme);
    localStorage.setItem('theme', theme);
    this.themeReplaySubject.next(theme);
  }
}
