import { AfterViewInit, Component, OnInit } from '@angular/core';
import { StatusService } from '@services/status.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'novus-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {
  frontendVersion = environment.appVersion;
  status?: { memUsage?: number, uptime?: string, numberOfUsers: number, dotnetRuntime: string, appVersion: string };

  constructor(
    private statusService: StatusService
  ) {
  }

  ngAfterViewInit() {
    this.statusService.getStatus()
    .subscribe((s: any) => {
      this.status = s;
    });
  }

}
