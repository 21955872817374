import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent {

  constructor() { }

}
