<main>
  <novus-input-card color="accent">
    <span title i18n="@@Profile">Profile</span>

    <form (ngSubmit)="update()" [formGroup]="form">
      <mat-form-field>
        <mat-label i18n="@@Username">Username</mat-label>
        <input matInput placeholder="user1234" formControlName="username">
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Email">Email</mat-label>
        <input matInput placeholder="user1234" formControlName="email">
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Fullname">Full name</mat-label>
        <input matInput placeholder="user1234" formControlName="fullName">
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@PhoneNr">Phone number</mat-label>
        <input matInput placeholder="user1234" formControlName="phone">
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="@@Address">Address</mat-label>
        <input matInput placeholder="user1234" formControlName="address">
      </mat-form-field>
    </form>

    <button action mat-raised-button color="success" i18n="@@UpdateButton" (click)="update()">Update</button>
  </novus-input-card>

  <novus-input-card color="accent">
    <span title i18n="@@Appearance">Appearance</span>

    <form (ngSubmit)="saveAppearance()" [formGroup]="appearance">
      <mat-form-field>
        <mat-label i18n="@@Language">Language</mat-label>
        <mat-select formControlName="language">
          <mat-option value="en">English</mat-option>
          <mat-option value="nl">Nederlands</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@HomePage">Home page</mat-label>
        <input matInput placeholder="/" formControlName="homePage">
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@Theme">Theme</mat-label>
        <mat-select formControlName="theme">
          <mat-option value="light">Light</mat-option>
          <mat-option value="dark">Dark</mat-option>
        </mat-select>
      </mat-form-field>

    </form>

    <button action mat-raised-button color="success" (click)="saveAppearance()" i18n="@@SaveButton">Save</button>
  </novus-input-card>

  <novus-input-card color="warn">
    <span title i18n="@@DeleteAccountButton">Delete account</span>

    <mat-form-field>
      <mat-label i18n="@@Password">Password</mat-label>
      <input matInput type="password" required [(ngModel)]="password">
    </mat-form-field>

    <button action mat-raised-button color="warn" i18n="@@ConfirmAccountDeleteButton" (click)="selfDelete()">Confirm deletion</button>
  </novus-input-card>
</main>
