import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanComponentDeactivate } from '@core/auth.guard';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'novus-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements CanComponentDeactivate, AfterViewInit {

  password: string = '';

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngAfterViewInit() {
    if (!this.authService.userValue?.requirePasswordChange) {
      this.router.navigate(['/']);
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.user
    .pipe(
      map(u => {
        if (u === undefined) {
          return true;
        }
        return !u.requirePasswordChange;
      })
    );
  }

  updatePassword() {
    this.authService.changePassword(this.password)
    .subscribe(() => {
      this.router.navigate(['/']);
    });
  }

}
