import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '@shared/shared.module';
import { JwtInterceptor } from '@core/jwt.interceptor';
import { ErrorInterceptor } from '@core/error.interceptor';
import { appInitializer } from '@core/app.initializer';
import { AuthenticationService } from '@services/authentication.service';
import { ThemeSelectorService } from '@services/theme-selector.service';

import { PublicModule } from './public/public.module';
import { CoreModule } from './core/core.module';
import { PrivateModule } from './private/private.module';
import { AdminModule } from './admin/admin.module';
import { AppsModule } from './apps/apps.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    PublicModule,
    SharedModule,
    CoreModule,
    PrivateModule,
    AppsModule,
    AdminModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory : appInitializer, multi: true, deps: [AuthenticationService, ThemeSelectorService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
