import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { Role } from '@core/models/role';
import { User } from '@core/models/user';

// export interface User {
//   id: number;
//   username: string;
//   email: string;
//   created: Date;
//   last: Date;
// }
const USERS_DATA: User[] = [
//   { id: 1, username: 'john', email: 'john@doe.com', created: new Date(), last: new Date() },
//   { id: 2, username: 'jane', email: 'jane@doe.com', created: new Date(), last: new Date() }
];

@Component({
  selector: 'novus-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements AfterViewInit {


  users = USERS_DATA;
  displayedColumns = ['id', 'username', 'email', 'role', 'created', 'lastlogin', 'actions'];

  constructor(
    private userService: UserService
  ) { }

  ngAfterViewInit() {
    this.refresh();
  }

  delete(id: number) {
    this.userService.delete(id)
    .subscribe(() => this.refresh());
  }

  refresh() {
    this.userService.getAll()
    .subscribe(data => this.users = data);
  }

  isAdmin(user: User) {
    return user.role == Role.Admin;
  }

}
