<main>
  <novus-input-card color="accent">
    <span title>User management</span>

    <section>
      <table mat-table [dataSource]="users">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Id </th>
          <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> Username </th>
          <td mat-cell *matCellDef="let row"> {{ row.username }} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let row"> {{ row.email }} </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> Admin </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="isAdmin(row); else not">check</mat-icon>
            <ng-template #not><mat-icon>close</mat-icon></ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef> Created </th>
          <td mat-cell *matCellDef="let row"> {{ row.created | date }} </td>
        </ng-container>

        <ng-container matColumnDef="lastlogin">
          <th mat-header-cell *matHeaderCellDef> Last seen </th>
          <td mat-cell *matCellDef="let row"> {{ row.lastLogin | date }} </td>
        </ng-container>

        <ng-container matColumnDef="actions" justify="end">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button routerLink="edit/{{ row.id }}">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="delete(row.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </section>

    <button action color="success" mat-raised-button routerLink="new">Add user</button>
  </novus-input-card>
</main>
