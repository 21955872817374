import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: string | undefined, ...args: unknown[]): unknown {
    if (value == undefined) {
      return value;
    }
    const patternshort = '([0-9]*):([0-9]*):([0-9]*).([0-9]*)';
    const patternlong = '([0-9]*).([0-9]*):([0-9]*):([0-9]*).([0-9]*)';

    if (value.split('.').length == 3) {
      const match = value.match(patternlong);
      const days = match && parseInt(match[1]);
      const hours = match && parseInt(match[2]);
      const minutes = match && parseInt(match[3]);
      const seconds = match && parseInt(match[4]);
      const milliseconds = match && parseInt(match[5]);
      return `${days} days ${hours} hours ${minutes} minutes and ${seconds} seconds`;
    } else {
      const match = value.match(patternshort);
      const hours = match && parseInt(match[1]);
      const minutes = match && parseInt(match[2]);
      const seconds = match && parseInt(match[3]);
      const milliseconds = match && parseInt(match[4]);
      return `${hours} hours ${minutes} minutes and ${seconds} seconds`;
    }
    // return value;
  }

}
