<main>
  <novus-sidebar-layout>
    <aside link>
    </aside>

    <section>
      <router-outlet></router-outlet>
    </section>
  </novus-sidebar-layout>
</main>
