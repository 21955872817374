<main>
  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" opened>
      <ng-content select="[link]"></ng-content>
    </mat-sidenav>
    <mat-sidenav-content>
      <section>
        <aside>
          <button [class.closed]="!drawer.opened" mat-icon-button (click)="drawer.toggle()">
            <mat-icon>menu_open</mat-icon>
          </button>
        </aside>
        <div>
          <ng-content></ng-content>
        </div>
      </section>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
