import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';

import { SharedModule } from '@shared/shared.module';
import { UserMenuComponent } from './header/user-menu/user-menu.component';


@NgModule({
  declarations: [
    HeaderComponent,
    UserMenuComponent
  ],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class CoreModule { }
