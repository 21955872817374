import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@services/user.service';

import { ApiDialogComponent } from './api-dialog/api-dialog.component';

import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'novus-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {

  keys: any[] = [];

  constructor(
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.refresh();
  }

  deleteApiKey(id: number) {
    this.userService.deleteApiKey(id)
    .subscribe(() => this.refresh());
  }

  newKey() {
    const ref = this.dialog.open(ApiDialogComponent);
    ref.afterClosed().subscribe(() => this.refresh());
  }

  refresh() {
    this.userService.getApiKeys()
    .subscribe(k => {
      this.keys = k;
    });
  }

}
