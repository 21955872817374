<h1 mat-dialog-title>About</h1>
<div mat-dialog-content>
  <dl>
    <dt>Author</dt>
    <dd>Some User</dd>

    <dt>Powered by</dt>
    <dd>NovusWeb</dd>

    <dt>Version</dt>
    <dd>v0.0.1</dd>
  </dl>
</div>
