import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';
import { User } from '@core/models/user';
import { Role } from '@core/models/role';

@Component({
  selector: 'novus-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  password = '';
  user?: User;

  form: UntypedFormGroup;

  loading = false;
  submitted = false;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      username: [''],
      password: [''],
      email: [''],
      admin: [false],
      requirePasswordUpdate: [false]
    });
  }

  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.userService.getById(id)
    .subscribe(u => {
      this.f['username'].setValue(u.username);
      this.f['email'].setValue(u.email);
      this.f['requirePasswordUpdate'].setValue(u.requirePasswordChange);
      this.f['admin'].setValue(u.role == Role.Admin);
      this.user = u;
    });
  }

  get f() { return this.form.controls; }

  update() {
    if (this.user?.id == undefined) return;

    this.submitted = true;
    this.loading = true;

    const payload = {
      username: this.f['username'].value,
      email: this.f['email'].value,
      password: this.f['password'].value,
      isAdmin: this.f['admin'].value,
      requirePasswordUpdate: this.f['requirePasswordUpdate'].value
    }

    this.userService.update(this.user.id, payload)
    .subscribe(() => {
      this.router.navigate(['/admin/users']);
    });
  }

}
