import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AppsComponent } from './apps.component';



@NgModule({
  declarations: [
    AppsComponent
  ],
  imports: [
    SharedModule
  ]
})
export class AppsModule { }
