import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/auth.guard';
import { Role } from '@core/models/role';
import { AdminComponent } from './admin.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SystemLogsComponent } from './system-logs/system-logs.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { AppsComponent } from './apps/apps.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'apps', component: AppsComponent },
      { path: 'configuration', component: ConfigurationComponent },
      { path: 'system-logs', component: SystemLogsComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
