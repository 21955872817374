import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {

  constructor() { }

}
