import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.css']
})
export class PrivateComponent {

  constructor() { }

}
