import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppsComponent } from './apps.component';

const routes: Routes = [
  { path: 'apps', component: AppsComponent, children: [
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppsRoutingModule { }
