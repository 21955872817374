<main>
  <novus-input-card>
    <span title>Change password</span>

    <mat-form-field>
      <mat-label>New password</mat-label>
      <input matInput type="password" required [(ngModel)]="password">
    </mat-form-field>

    <button action mat-raised-button color="success" (click)="updatePassword()">Update password</button>
  </novus-input-card>
</main>
