<novus-input-card color="accent">
  <span title>Edit user account</span>

  <form [formGroup]="form" (ngSubmit)="update()">
    <mat-form-field>
      <mat-label i18n="@@Username">Username</mat-label>
      <input matInput placeholder="user1234" formControlName="username">
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@Email">Email</mat-label>
      <input matInput placeholder="user1234" formControlName="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@Password">Password</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>

    <mat-checkbox formControlName="admin">Admin</mat-checkbox>
    <mat-checkbox formControlName="requirePasswordUpdate">Require password update</mat-checkbox>
  </form>

  <button action mat-raised-button color="success" i18n="@@UpdateButton" (click)="update()">Update</button>
</novus-input-card>
