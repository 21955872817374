import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '@services/user.service';

@Component({
  selector: 'novus-api-dialog',
  templateUrl: './api-dialog.component.html',
  styleUrls: ['./api-dialog.component.css']
})
export class ApiDialogComponent {
  key: string = '';
  submitted: boolean = false;

  name = new FormControl('');
  expiration = new FormControl(null);

  constructor(
    private userService: UserService
  ) { }

  addApiKey() {
    if (this.name.value == undefined) return;
    if (this.name.value.length == 0) return;
    const data: { name: string, expires?: Date } = { name: this.name.value };
    if (this.expiration.value != undefined)
      data.expires = this.expiration.value;

    this.submitted = true;
    this.userService.addApiKey(data)
    .subscribe((key: any) => this.key = <string>(key['message']));
  }

}
