import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminNavbarComponent } from './admin-navbar/admin-navbar.component';
import { UsersModule } from './users/users.module';
import { SystemLogsComponent } from './system-logs/system-logs.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { AppsModule } from './apps/apps.module';


@NgModule({
  declarations: [
    AdminComponent,
    DashboardComponent,
    AdminNavbarComponent,
    SystemLogsComponent,
    ConfigurationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdminRoutingModule,
    UsersModule,
    AppsModule
  ]
})
export class AdminModule { }
