import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {

  constructor() { }

}
