import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {

  transform(bytes: number | undefined): unknown {
    if (bytes === undefined) return '';
    const suffix: string[] = [ "B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB" ];
    let index = 0;
    do { bytes /= 1024; index++; }
    while (bytes >= 1024);
    return `${bytes.toFixed(2)} ${suffix[index]}`;
  }

}
