import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {

  constructor() { }

}
