import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.css']
})
export class AppsComponent {

  constructor() { }

}
