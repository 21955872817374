import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '@services/user.service';

@Component({
  selector: 'novus-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class NewComponent {

  loading = false;
  submitted = false;

  form: UntypedFormGroup;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      email: [''],
      requireChange: [false]
    });
  }

  get f() { return this.form.controls; }

  create() {
    console.log('yo');
    this.submitted = true;

    if (this.form.invalid) {
      console.log('invalid');
      return;
    }

    console.log({
      username: this.f['username'].value,
      email: this.f['email'].value,
      password: this.f['password'].value,
      requirePasswordChange: this.f['requireChange'].value
    });

    this.loading = true;
    this.userService.createUser({
      username: this.f['username'].value,
      email: this.f['email'].value,
      password: this.f['password'].value,
      requirePasswordUpdate: this.f['requireChange'].value
    })
    .subscribe({
      next: () => {
        this.router.navigateByUrl('/admin/users');
      },
      error: err => {
        console.log(err);
        this.loading = false;
      }
    });
  }

  usernameError() {
    return this.form.controls['username'].errors && this.form.controls['username'].errors['required'];
  }

  passwordError() {
    return this.form.controls['password'].errors && this.form.controls['password'].errors['required'];
  }
}
