import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';

import { SharedModule } from '@shared/shared.module';
import { SidebarLayoutComponent } from './sidebar-layout/sidebar-layout.component';



@NgModule({
  declarations: [
    MainComponent,
    SidebarLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    MainComponent,
    SidebarLayoutComponent
  ]
})
export class LayoutsModule { }
