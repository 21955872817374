import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.css']
})
export class AdminNavbarComponent {

  constructor() { }

}
