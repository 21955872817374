import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'novus-input-card',
  templateUrl: './input-card.component.html',
  styleUrls: ['./input-card.component.css']
})
export class InputCardComponent {

  @Input()
  color: string;


  constructor() {
    this.color = '';
  }

}
