import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'novus-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  title = $localize `:A test thing|Test:hello`;

  constructor() { }

}
