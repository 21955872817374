import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@core/models/user';

import { iif, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'novus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  userPath = '/';

  currentPath: string;
  user?: User;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.currentPath = '';
    this.router.events
    .pipe(
      filter(e => e instanceof NavigationEnd),
    )
    .subscribe(u => {
      if (!(u instanceof NavigationEnd)) return;
      this.currentPath = u.url;
    });

    this.authenticationService.user
    .pipe(
      switchMap(t => iif(() => !!t, this.userService.getById(t?.userId || -1), of(undefined)))
    )
    .subscribe(u => {
      this.user = u;
      this.userPath = u?.homePage || '/';
    });
  }

  get isLoggedIn() {
    return !!this.user;
  }

  get currentPathIsDefault() {
    return !!this.user && this.currentPath == this.userPath;
  }

  setCurrentPathDefault() {
    this.userPath = this.currentPath;

    if (this.user?.id == undefined) return;
    this.userService
    .update(this.user.id, { homePage: this.userPath })
    .subscribe();
  }

  goToDefaultPath() {
    this.router.navigate([this.userPath]);
  }

}
