<main>
  <form [formGroup]="form" (ngSubmit)="login()">
    <header>Sign in</header>
    <section>
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" />
        <mat-error *ngIf="submitted && usernameError()">Username is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" />
        <mat-error *ngIf="submitted && passwordError()">Password is required</mat-error>
      </mat-form-field>
      <mat-checkbox formControlName="remember">Keep me logged in</mat-checkbox>
    </section>
    <footer>
      <button mat-button color="success">Log in</button>
    </footer>
  </form>
</main>
