<novus-input-card color="accent">
  <span title>Server status</span>

  <section>
    <div>
      <label>Frontend version</label>
      <span>{{ frontendVersion }}</span>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section>
    <div>
      <label>Server uptime</label>
      <span>{{ status?.uptime | time }}</span>
    </div>
    <div>
      <label>API version</label>
      <span>{{ status?.appVersion }}</span>
    </div>
    <div>
      <label>.NET Runtime version</label>
      <span>{{ status?.dotnetRuntime }}</span>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section>
    <div>
      <label>Memory usage</label>
      <span>{{ status?.memUsage | bytes }} MiB</span>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section>
    <div>
      <label>Users</label>
      <span>{{ status?.numberOfUsers }}</span>
    </div>
  </section>
</novus-input-card>
