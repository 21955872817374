<button mat-button [matMenuTriggerFor]="menu" *ngIf="isLoggedIn; else loginButton">
  <mat-icon>account_circle</mat-icon>
  {{ user?.username }}
  <mat-icon>expand_more</mat-icon>
</button>
<ng-template #loginButton>
  <button mat-menu-item routerLink="/login">
    <ng-container i18n="@@LoginButton">Log in</ng-container>
  </button>
</ng-template>

<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/apps">
    <mat-icon>dashboard</mat-icon>
    Dashboard
  </button>
  <button mat-menu-item routerLink="/settings/user">
    <mat-icon>manage_accounts</mat-icon>
    <ng-container i18n="@@AccountSettingsButton">Account Settings</ng-container>
  </button>
  <button mat-menu-item routerLink="/admin" *ngIf="isAdmin">
    <mat-icon>admin_panel_settings</mat-icon>
    <ng-container i18n="@@AdminSettingsButton">Admin Settings</ng-container>
  </button>
  <button mat-menu-item (click)="openAbout()">
    <mat-icon>info</mat-icon>
    <ng-container i18n="@@AboutButton">About</ng-container>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <ng-container i18n="@@LogoutButton">Log out</ng-container>
  </button>
</mat-menu>
