import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { InputCardComponent } from './input-card/input-card.component';
import { TimePipe } from './time.pipe';
import { BytesPipe } from './bytes.pipe';


@NgModule({
  declarations: [
    InputCardComponent,
    TimePipe,
    BytesPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    InputCardComponent,
    TimePipe,
    BytesPipe
  ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS: useClass: JwtInterceptor, multi: true }
  ]
})
export class SharedModule { }
