import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { CookieService } from '@services/cookie.service';

import { AuthenticationService } from '@services/authentication.service';
@Component({
  selector: 'novus-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  form: UntypedFormGroup;

  loading = false;
  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private cookies: CookieService
  ) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: [true]
    });
  }

  get f() { return this.form.controls; }

  login() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.authService.login(this.f['username'].value, this.f['password'].value, this.f['remember'].value)
    .pipe(
      first()
    )
    .subscribe({
      next: (user) => {
        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || user.homePage || '/';
        if (user.requirePasswordChange) {
          returnUrl = 'reset-password';
        }
        this.router.navigateByUrl(returnUrl)
        .then(p => {
          if (this.cookies.getCookie('lang') !== user.language) {
            this.cookies.setCookie('lang', user.language, 100 * 365);
            location.reload();
          }
        });
      },
      error: error => {
        this.snackBar.open(error, '', { duration: 5000 });
        this.loading = false;
      }
    });
  }

  usernameError() {
    return this.form.controls['username'].errors && this.form.controls['username'].errors['required'];
  }

  passwordError() {
    return this.form.controls['password'].errors && this.form.controls['password'].errors['required'];
  }

}
