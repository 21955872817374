import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { LayoutsModule } from '@layouts/layouts.module';

import { SharedModule } from '@shared/shared.module';
import { PersonalComponent } from './personal/personal.component';
import { SecurityComponent } from './security/security.component';
import { ApiDialogComponent } from './security/api-dialog/api-dialog.component';


@NgModule({
  declarations: [
    UserComponent,
    PersonalComponent,
    SecurityComponent,
    ApiDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule,
    LayoutsModule
  ]
})
export class UserModule { }
