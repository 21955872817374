import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@core/models/user';
import { AuthToken } from '@core/models/token';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userSubject: BehaviorSubject<AuthToken | undefined>;
  public user: Observable<AuthToken | undefined>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookies: CookieService
  ) {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const json = JSON.parse(storedUser);
      this.userSubject = new BehaviorSubject<AuthToken | undefined>(json);
    } else {
      this.userSubject = new BehaviorSubject<AuthToken | undefined>(undefined);
    }
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): AuthToken | undefined {
    return this.userSubject.value;
  }

  refresh() {
    if (this.userValue) {
      return this.http.post<any>(`${environment.apiUrl}/users/refresh-token`, {})
      .pipe(
        map(user => {
          if (this.cookies.getCookie('lang') !== user.language) {
            this.cookies.setCookie('lang', user.language, 100 * 365);
            location.reload();
          }
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
    } else {
      return of(null);
    }
  }

  login(username: string, password: string, remember = true) {
    return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
    .pipe(
      map(user => {
        if (remember)
          localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(undefined);
    this.router.navigate(['/login']);
  }

  requirePasswordChange() {
    this.router.navigate(['reset-password']);
  }

  changePassword(password: string) {
    return this.http.put(`${environment.apiUrl}/users/${this.userValue?.userId}`, { requirePasswordUpdate: false, password })
    .pipe(
      switchMap(() => this.refresh())
    )
  }

}
